@import "../assets/styles/variables";

.container {
  display: flex;
  flex-direction: row;
  // padding: 1rem;
  align-items: flex-start;
  justify-content: space-between;

  .labelContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .switchContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    // Switch Styling
    input[type="checkbox"] {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    label {
      display: flex;
      cursor: pointer;
      width: 54px;
      height: 30px;
      border: 1px solid $gray-2;
      border-radius: 100px;
      position: relative;
      text-indent: -9999px;
    }

    input[type="checkbox"]:checked + label {
      border: 1px solid $charcoal;
    }

    label:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 3px;
      width: 24px;
      height: 24px;
      background: $charcoal;
      border-radius: 90px;
      transition: 0.3s;
    }

    input:checked + label {
      background: #f5dadf;
    }

    input:checked + label:after {
      left: calc(100% - 3px);
      transform: translateX(-100%);
    }

    label:active:after {
      width: 40px;
    }
  }
}
