@import "../../assets/styles/helpers";

.outerContainer {
  background-image: url("../../assets/img/assetAuthBackground.jpg");
  background-size: cover;
  background-position: center center;

  padding-top: 160px;
  padding-bottom: 160px;

  @include media-breakpoint-down(md) {
    background: none;
    padding: 0;
  }
}

.card {
  background-color: white;

  margin: 0 auto;
  max-width: 700px;
  padding: 30px 100px;

  @include media-breakpoint-down(md) {
    padding: 30px 20px;
  }
}

.toggleContainer {
  background-color: rgba(246, 242, 240, 1);
  padding: 8px 16px;
  border-radius: 25px;
  height: 50px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  margin: 0 40px;

  @include media-breakpoint-down(md) {
    margin: 0;
    padding: 8px;
  }

  .link {
    display: block;
    text-align: center;
    flex: 1;
    line-height: 32px;
    border-radius: 16px;
    font-family: "ApercuPro", sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: rgba(45, 41, 38, 1);

    &.active {
      background-color: rgba(245, 218, 223, 1);
    }

    &:first-child {
      margin-right: 4px;
    }

    @include media-breakpoint-down(md) {
      font-size: 15px;
    }
  }
}

.contentContainer {
  padding-top: 40px;
}
