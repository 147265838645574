@import "../../../assets/styles/variables";

.pressableArea {
  background: none;
  border: none;

  max-width: 300px;
}

.plusContainer {
  border: none;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  background-color: $pink;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner {
  animation: spinAround 0.5s infinite linear;
  -webkit-animation: spinAround 0.5s infinite linear;
}

.codeContainer {
  border: none;
  background-color: $gray-4;
  max-width: 320px;
}

.code {
  font-family: "ApercuPro", sans-serif;
  font-weight: normal;
  color: $charcoal;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.1em;
}
