@import "../../../assets/styles/variables";

.container {
  padding: 16px;
  padding-left: 8px;
  .cup {
    margin-top: -40px;
  }
  .subtitle {
    color: $gray-1-5;
  }
}
