@import "../assets/styles/source/imports/animations";
@import "../assets/styles/source/imports/base";

.base {
  height: 48px;
  text-align: center;
  line-height: 48px;
  border-radius: 5px;
  cursor: pointer;

  font-family: "ApercuPro", sans-serif;
  font-weight: 500;
  font-size: 15px;

  &:disabled {
    cursor: not-allowed;
  }

  &.loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;
  }
}

.primary {
  background-color: rgba(45, 41, 38, 1);
  border: 0;
  color: white;
  padding: 0;

  &:disabled {
    background-color: rgba(227, 227, 226, 1);
  }

  &.loading::after {
    @include is-loading(white, 2px);
  }
}

.secondary {
  border: 1px solid rgba(45, 41, 38, 1);
  background-color: transparent;
  color: rgba(45, 41, 38, 1);

  &:disabled {
    border-color: rgba(227, 227, 226, 1);
    color: rgba(227, 227, 226, 1);

    &.loading::after {
      @include is-loading(rgba(227, 227, 226, 1), 2px);
    }
  }

  &:hover {
    color: rgba(45, 41, 38, 1);
  }

  &.loading::after {
    @include is-loading(rgba(45, 41, 38, 1), 2px);
  }
}
