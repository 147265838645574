@import "../assets/styles/variables";

.optionCard {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
}

.activeCard {
  border: 1px solid $charcoal;
  background-color: $gray-4;
}

.image {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1.3;
  border-radius: 4px;
}