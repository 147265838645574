@mixin base-headline {
  font-family: "Argent", serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  color: $charcoal;
}

@mixin base-body {
  font-family: "ApercuPro", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin base-caption {
  font-family: "ApercuPro", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

.headline-40 {
  @include base-headline();

  font-size: 40px;
  line-height: 48px;
}

.headline-28 {
  @include base-headline();

  font-size: 28px;
  line-height: 32px;
}

.body-20 {
  @include base-body();

  font-size: 20px;
  line-height: 25px;
}

.body-17 {
  @include base-body();

  font-size: 17px;
  line-height: 22px;
}

.body-15 {
  @include base-body();

  font-size: 15px;
  line-height: 20px;
}

.body-13 {
  @include base-body();

  font-size: 13px;
  line-height: 17px;
}

.caption-13 {
  @include base-caption();

  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
}

.medium {
  font-weight: 500;
}

.color-charcoal {
  color: $charcoal;
}

.color-gray-0 {
  color: $gray-0;
}

.color-gray-1 {
  color: $gray-1;
}

.color-gray-1-5 {
  color: $gray-1-5;
}

.color-gray-2 {
  color: $gray-2;
}

.color-negative {
  color: $red;
}

.color-green {
  color: $green;
}

.fw-medium {
  font-weight: 500;
}

.badge {
}
