@import "../assets/styles/variables";

.group {
  border-radius: 8px;
  border: 1px solid $gray-1-5;
  padding: 0.5rem;
  margin: 1rem 0;
}

.icon {
  max-width: 36px;
}

.specialRadio {
  cursor: pointer;
  min-width: 100px;
  // When option is selected
  input[type="radio"] + label {
    .imageBorder {
      border: 1px solid $gray-1-5;
      cursor: pointer;
      border-radius: 4px;
      padding: 1rem 0.5rem;
      background: white;
      transition: 500ms background;
    }
  }

  input[type="radio"]:checked + label {
    border: none;
    .imageBorder {
      border: 1px solid $charcoal;
      background: $gray-4;
    }
  }
}
