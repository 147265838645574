@import "../../../assets/styles/variables";

.container {
  background-color: $green;
  border: 2px solid $charcoal;
  border-radius: 12px;
  height: 24px;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: white;
    line-height: 17px;
  }
}
