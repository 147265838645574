@import "../assets//styles/variables";

.navbar {
  box-shadow: inset 0px -1px 0px #c9c4c1;
  margin: 0 -1.5rem 22px;
  padding: 0 1.5rem;
}

.navLink {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  color: $gray-1;
  text-decoration: none !important;
  margin: 0 24px;
  p {
    font-weight: bold;
    color: $gray-1;
    text-align: center;
  }
  &:hover {
    box-shadow: inset 0px -4px 0px $charcoal;
    text-decoration: none !important;
    p {
      color: $charcoal;
      font-weight: bold;
    }
  }
}
.navLink:first-child {
  margin-left: 0 !important;
}
.navLink:last-child {
  margin-right: 0 !important;
}

.activeNavLink {
  box-shadow: inset 0px -4px 0px $charcoal;
  text-decoration: none !important;
  p {
    color: $charcoal !important;
    font-weight: bold;
    text-decoration: none !important;
  }
}

.accordionNav {
  margin: 0 -16px;
}
.accordionLink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px;
  text-decoration: none !important;
  margin: 0;
  p {
    font-weight: bold;
  }
  &:hover {
    box-shadow: inset 0px -4px 0px $charcoal;
    text-decoration: none !important;
    p {
      color: $charcoal;
      font-weight: bold;
    }
  }
}
