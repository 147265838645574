.container {
  max-width: 400px;
  .appIcon {
    border-radius: 12px;
    width: 76px;
    height: 76px;
  }

  .appStoreCta {
    width: 110px;
  }
}
