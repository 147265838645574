@import "../assets/styles/variables";

.card {
  padding: 24px 20px;
  border: 1px solid #c9c4c1;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  overflow: hidden;
  @media (max-width: $screen-md) {
    padding: 20px 16px;
  }
}
