@import "../assets/styles/variables";

.price {
  color: $green !important;
}

.square {
  width: 100%;
  padding-bottom: 75%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
