@import "../../assets/styles/helpers";

.buttonContainer {
  padding: 0 64px;
  margin-top: 40px;

  @include media-breakpoint-down(md) {
    padding: 0px;
  }
}

.tryAgain {
  font-family: "ApercuPro", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: rgba(45, 41, 38, 1);
  text-align: center;

  .underline {
    color: rgba(45, 41, 38, 1) !important;
    text-decoration: underline !important;
  }
}
