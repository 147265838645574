html {
  background-color: white !important;
}

html,
body,
#root,
.app-root {
  max-width: 100%;
}

html,
#root,
.app-root {
  height: 100%;
}

body,
#root,
.app-root {
  overflow-x: hidden;
}

.app-root {
  display: flex;
  flex-direction: column;
}

.header {
  flex-shrink: 0;
  height: 80px;
  z-index: 500;
}

.content {
  flex: 1 1 auto;
  margin-top: 80px;
}

.footer {
  flex-shrink: 0;
}

.flex-1 {
  flex: 1;
}
