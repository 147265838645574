.quantity {
  position: absolute;
  bottom: 3px;
  right: 3px;
  height: 24px;
  width: 24px;
  justify-content: center;
  display: flex;
  align-items: center;
  p {
    margin: 0;
    line-height: 1;
  }
}

.imageContainer {
  height: fit-content;
  img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    max-width: unset;
  }
}
