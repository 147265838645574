@import "../../assets/styles/variables";

.header {
  top: 0;

  &.pink {
    background-color: $pink;
  }
}

.logoutDropdown {
  position: absolute;
  top: 45px;
  right: 0;
  cursor: pointer;
}

.hamburger {
  left: 15px;
}
