.error {
  font-family: "ApercuPro", sans-serif;
  font-size: 15;
  color: rgba(213, 41, 42, 1);
  margin-left: -0.4rem;
  margin-top: 0.5rem;
}

.resend {
  color: rgba(45, 41, 38, 1) !important;
  font-family: "ApercuPro", sans-serif;
  font-size: 12;
  font-weight: 500;
  text-decoration: underline !important;
}
