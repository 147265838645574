@import "../../assets/styles/helpers";

.buttonContainer {
  padding: 0 64px;
  margin-top: 40px;

  @include media-breakpoint-down(md) {
    padding: 0px;
  }
}

.forgotPassword {
  font-family: "ApercuPro", sans-serif;
  font-size: 12px;
  text-decoration: underline !important;
  font-weight: 500;
  color: rgba(45, 41, 38, 1) !important;
  text-align: center;
}
