@import "../../assets/styles/variables";

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: $charcoal;
}
