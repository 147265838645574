@import "../../assets/styles/variables";

.container {
  background-color: $gray-0;
  height: 4px;
  border-radius: 2px;

  .innerBar {
    background-color: $green;
    border-radius: 2px;
  }
}
