@import "../assets/styles/variables";

.icon {
  width: 12px;
  height: 12px;
}

.badge {
  height: 25px;
  // min-width: 105px;
  border-radius: 100px;
  background-color: $pink;

  position: absolute;
  top: 10px;
  right: 10px;
}
