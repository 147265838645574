@import "../../../assets/styles/variables";

.container {
  .pointContainer {
    .points {
      font-family: "ApercuPro", sans-serif;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;

      font-size: 40px;
      line-height: 40px;
      color: white;
    }
  }

  .progressContainer {
    background-color: rgba(112, 108, 105, 0.2);

    .cup {
      margin-top: -30px;
    }

    .availableRewardCount {
      position: absolute;
      right: 2px;
      bottom: 10px;
    }
  }
}
