.container {
}
.field {
  color: rgba(45, 41, 38, 1);
  font-family: "ApercuPro", sans-serif;
  font-size: 17px;
  border: none;
  border-bottom: 1px solid rgba(45, 41, 38, 1);

  padding: 9px 0;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: "ApercuPro", sans-serif;
    font-size: 17px;
    color: rgba(45, 41, 38, 0.6);
  }
}

.errorText {
  font-family: "ApercuPro", sans-serif;
  font-size: 15px;
  color: rgba(213, 41, 42, 1);
}
