@import "../../assets/styles/variables";

.rewardRow {
  padding: 16px;
  background-color: #f8f7f7;

  margin-left: -20px;
  margin-right: -20px;
}

.rewardContainer {
  background-color: white;
  border: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 160px;
  margin: 8px;

  flex: 1;
}

.rewardImage {
  width: 60px;
  margin-top: -32px;
}
