.img {
  max-width: 480px;
}

.body {
  max-width: 440px;
}

.appButton {
  max-width: 150px;
}

.smallPrint {
  max-width: 250px;
}
