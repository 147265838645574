@import "../../assets/styles/variables";
@import "../../assets/styles/helpers";

.footer {
  @include media-breakpoint-down(md) {
    padding-bottom: 100px;
  }
}

.socialLink {
  margin-right: 10px;
  img {
    max-width: 25px;
  }
}
