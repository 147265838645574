@use "sass:math";
@import "./source/imports/variables";

$ph-bg: #fff !default;
$ph-color: $gray-3 !default;
$ph-border-radius: 2px !default;

$ph-gutter: 30px !default;
$ph-spacer: 15px !default;

$ph-avatar-border-radius: 50% !default;

$ph-animation-duration: 0.8s !default;

.ph-item {
  &,
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  direction: ltr;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: $ph-gutter math.div($ph-gutter, 2) ($ph-gutter - $ph-spacer)
    math.div($ph-gutter, 2);
  overflow: hidden;
  margin-bottom: $ph-gutter;
  background-color: $ph-bg;
  border: 1px solid darken($ph-bg, 10%);
  border-radius: $ph-border-radius;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation $ph-animation-duration linear infinite;
    background: linear-gradient(
        to right,
        rgba($ph-bg, 0) 46%,
        rgba($ph-bg, 0.35) 50%,
        rgba($ph-bg, 0) 54%
      )
      50% 50%;
  }

  > * {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    padding-right: math.div($ph-gutter, 2);
    padding-left: math.div($ph-gutter, 2);
  }
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: math.div($ph-gutter, 2);

  div {
    height: 10px;
    margin-bottom: math.div($ph-gutter, 2);
    background-color: $ph-color;
  }
  .big,
  &.big div {
    height: 20px;
    margin-bottom: $ph-spacer;
  }
  .empty {
    background-color: rgba($ph-bg, 0);
  }
}

.ph-col-2 {
  flex: 0 0 math.percentage(math.div(2, 12));
}
.ph-col-4 {
  flex: 0 0 math.percentage(math.div(4, 12));
}
.ph-col-6 {
  flex: 0 0 math.percentage(math.div(6, 12));
}
.ph-col-8 {
  flex: 0 0 math.percentage(math.div(8, 12));
}
.ph-col-10 {
  flex: 0 0 math.percentage(math.div(10, 12));
}
.ph-col-12 {
  flex: 0 0 math.percentage(math.div(12, 12));
}

.ph-avatar {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
  border-radius: $ph-avatar-border-radius;
  overflow: hidden;

  &::before {
    content: " ";
    display: block;
    padding-top: 100%;
  }
}

.ph-picture {
  width: 100%;
  height: 120px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}
