@use "sass:math";

/**
 * Mixins.
 * Base styles for all GKit elements.
 */
@mixin gkit-text {
  font-family: "ApercuPro", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  display: block;
}

@mixin gkit-badge {
  font-family: "ApercuPro", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  padding: 3px 6px;
  text-transform: uppercase;
}

@mixin gkit-round-text {
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: $charcoal;
  p {
    color: white !important;
  }
}

@mixin gkit-title {
  color: $charcoal;
  display: block;
  font-weight: bold;

  /* Interactions with following elements */
  + p[class*="gkit-p-"] {
    margin-top: 0.75rem;
  }

  + [class*="gkit-h"] {
    margin-top: 4.5625rem;
  }
}

@mixin gkit-body {
  font-weight: normal;
  color: $charcoal;

  + p[class*="gkit-p-"] {
    margin-top: 1em;
  }

  p + p {
    margin-top: 1em;
  }
}

@mixin gkit-btn($border-width, $font-size, $x-padding, $y-padding) {
  @include gkit-text();

  position: relative;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  border-radius: 4px;
  border-color: transparent;
  border-style: solid;
  cursor: pointer;
  text-decoration: none;

  border-width: $border-width;
  font-size: $font-size;
  padding-left: $x-padding;
  padding-right: $x-padding;
  padding-top: $y-padding;
  padding-bottom: $y-padding;
  line-height: 1.4em;

  &.gkit-loading {
    color: transparent !important;
    pointer-events: none;
  }
}

@mixin gkit-select {
  @include gkit-text();

  appearance: none !important;
  -webkit-appearance: none !important;

  cursor: pointer;
  display: block;
  width: 100%;

  font-weight: bold;
  text-align: left;

  border: 1px solid $gray-2;
  box-sizing: border-box;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6295 0L13 1.39309L6.5 8L0 1.39309L1.37054 0L6.5 5.21383L11.6295 0Z' fill='%232D2926'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}

@mixin gkit-input {
  @include gkit-text();

  display: block;
  border: 1px solid $gray-1-5;
  border-radius: 6px;
  width: 100%;

  font-size: 1.0625rem;
  font-weight: normal;

  color: $charcoal;

  padding-left: 12px;
  padding-right: 12px;

  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: $gray-1;
  }

  &.form-control:focus:not(.is-invalid) {
    border: 2px solid rgba(176, 168, 162, 1);
    box-shadow: 0 0 2px #b0a8a2;
  }
}

@mixin gkit-toggle {
  @include gkit-text();

  border: 1px solid $gray-1-5;
  background-color: white;
  cursor: pointer;

  font-weight: bold;
  font-size: 0.9375rem;

  text-align: center;
  vertical-align: middle;

  padding-left: 1.25rem;
  padding-right: 1.25rem;

  &:hover,
  &.selected {
    background-color: $gray-3;
  }
}

@mixin is-loading($color: white, $size: 2px) {
  left: calc(50% - math.div(1em, 2));
  top: calc(50% - math.div(1em, 2));
  position: absolute !important;
  animation: spinAround 0.5s infinite linear;
  -webkit-animation: spinAround 0.5s infinite linear;
  border-width: $size;
  border-style: solid;
  border-color: $color;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}
