@import "../assets/styles/variables";

.card-grid {
  .card-body {
    padding: 12px;
  }
  &.row:not(.no-gutters) {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  &.row:not(.no-gutters) > .col,
  &.row:not(.no-gutters) > [class*="col-"] {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}

.card-grid:not(.card-grid-row) {
  min-height: calc(25 * var(--vh));
}

@media only screen and (max-width: $screen-sm) {
  .card-grid:not(.card-grid-row) {
    min-height: calc(25 * var(--vh));
  }
}

@media only screen and (max-width: $screen-sm) and (orientation: landscape) {
  .card-grid:not(.card-grid-row) {
    min-height: calc(50 * var(--vh));
  }
}
