/**
 * Fonts.
 * All on the same font family so weights aren't applied by the browser.
 */
@font-face {
  font-family: "ApercuPro";
  font-weight: normal;
  src: url("../../../fonts/apercu/apercu_regular_pro.otf");
}

@font-face {
  font-family: "ApercuPro";
  font-weight: bold;
  src: url("../../../fonts/apercu/apercu_bold_pro.otf");
}

@font-face {
  font-family: "ApercuPro";
  font-weight: 500;
  src: url("../../../fonts/apercu/apercu_medium_pro.otf");
}

@font-face {
  font-family: "Argent";
  src: url(../../../fonts/argent/ArgentRegular.otf);
  font-weight: normal;
}

@font-face {
  font-family: "Argent";
  src: url(../../../fonts/argent/ArgentDemiBold.otf);
  font-weight: 600;
}
