@import "../../../assets/styles/variables";

.optionValueContainer {
  margin-left: -4px;
  margin-right: -4px;
}

.optionValue {
  margin: 4px;
}

.complexValueContainer {
  padding: 12px 16px;

  background-color: white;
  border: 1.5px solid $gray-2;
  border-radius: 6px;

  &.active {
    border-color: $charcoal;
  }
}

.complexValueIcon {
  width: 48px;
}

.subscriptionListContainer {
  overflow-y: auto;
  max-height: 200px;
}

.rewardImage {
  width: 80px;
}

.subscriptionContainer {
  background-color: white;
  border: 1.5px solid $gray-2;
  border-radius: 6px;
  padding: 12px;

  &.active {
    border-color: $charcoal;
    background-color: $gray-4;
  }
}

.successBody {
  max-width: 320px;
}
