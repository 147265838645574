@import "../../assets/styles/helpers";

.navLink {
  display: flex;
  flex-grow: unset;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 16px 20px;
  color: $gray-1;
  text-decoration: none !important;
  border-bottom: 4px solid white;

  p {
    color: $gray-1;
  }

  &:hover,
  &.active {
    border-bottom: 4px solid $charcoal;

    p {
      color: $charcoal;
      font-weight: bold;
    }
  }

  @include media-breakpoint-down(md) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.navbar {
  display: flex;
  flex-grow: unset;

  @media (max-width: $screen-md) {
    flex-grow: 1;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.navbarCard {
  @media (max-width: 560px) {
    margin: 0 -15px;
  }
}
