@use "sass:math";
@import "../variables";

// Override React-rainbow-components styles

.react-rainbow-components {
  font-family: "ApercuPro" !important;
  -webkit-font-smoothing: antialiased;

  label {
    @include gkit-text();

    font-weight: bold;
    font-size: 1.0625rem;
    color: #181d38;

    line-height: 1.3em !important;
    margin: auto auto 5px 0;
  }

  input,
  .StripeElement {
    border-radius: 6px !important;
  }

  input:focus {
    border: 1px solid $charcoal-shadow !important;
    box-shadow: 0 0 0 2px $charcoal-shadow !important;
  }

  div[id^="error-message"] {
    margin-left: 0;
    margin-right: auto;
  }
}

.react-rainbow-phone {
  div {
    border-radius: 6px !important;
  }
  input:focus {
    border: none !important;
    box-shadow: none !important;
  }
}

section[role="dialog"] {
  max-height: 95vh !important;
  border-radius: 1rem !important;
}
div[role="listbox"] {
  border-radius: 6px !important;
}

#login-code {
  div {
    padding: 0 0.8rem;
  }
  input {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    margin: 0 6px;
    padding: 0;
  }
  @media (max-width: $screen-md) {
    input {
      width: 45px;
      height: 45px;
      max-width: calc((100vw - 124px) / 6);
      margin: 0 4px;
    }
  }
}

.react-rainbow-checkbox {
  legend {
    @include gkit-title();
    font-size: 20px;
    margin-bottom: 1.5rem;
  }
  label {
    margin-bottom: 2px;
  }
  .rainbow-input_faux {
    border-radius: 50% !important;
  }
  input:checked ~ label > .rainbow-input_faux {
    border: 2px solid $charcoal !important;
    background: $charcoal !important;
  }
  .rainbow-input_faux:after {
    border-bottom: 2px solid white !important;
    border-left: 2px solid white !important;
    border-top-color: white !important;
    border-right-color: white !important;
  }
  input:checked ~ label > .rainbow-input_faux::after {
    top: 37% !important;
  }
}

div[role="listbox"] li[data-selected="true"] svg {
  color: white !important;
  background: $charcoal !important;
  padding: 3px !important;
  border-radius: 50% !important;
  width: 1rem !important;
  height: 1rem !important;
}

.react-rainbow-radio {
  label {
    margin: auto !important;
  }
  div[data-id="input-radio_container"] {
    margin: auto;
  }
  input:checked ~ label > .rainbow-input_faux {
    border-color: $charcoal !important;
    background: $charcoal !important;
  }
  input:checked ~ label > .rainbow-input_faux::after {
    background: white !important;
  }
}

#hamburger-menu {
  button[data-id="button-icon-element"] svg {
    fill: $charcoal !important;
  }
}

.react-rainbow-modal-close {
  -webkit-transition: all 0.2s linear !important ;
  transition: all 0.2s linear !important ;
  top: auto !important;
  bottom: -100% !important;
}
