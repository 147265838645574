$gray-0: #706c69;
$gray-1: #959291;
$gray-1-5: #c9c4c1;
$gray-2: #e2dbd5;
$gray-3: #f2efee;
$gray-4: #f8f7f7;
$gray-5: #EFEFF4;
$pink: #f5dadf;
$green: #52b57a;
$pale-green: #e7f1eb;
$red: #e2255e;
$danger: #e2255e;
$light-pink: #ffeff2;
$washed-pink: #fbeff1;
$yellow: #fff0d2;
$brown: #c16006;
$cream: #fef8f4;

$charcoal: #2d2926;
$charcoal-shadow: lighten($charcoal, 50%);
