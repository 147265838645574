@import "../../assets/styles/variables";

.card {
  background-color: $charcoal;
  border-color: $charcoal;
  overflow: visible;
  //border-bottom-right-radius: 0;
  //border-bottom-left-radius: 0;
}

.points {
  font-family: "ApercuPro", sans-serif;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;

  font-size: 60px;
  line-height: 75px;
  color: white;
}

.cupContainer {
  padding: 0 20px 26px;
  flex-shrink: 0;
  .cup {
    width: 89px;
    margin-top: -16px;
  }

  .availableRewardCount {
    position: absolute;
    top: 66px;
    right: 25px;
  }

  &.activeAccount {
    background-color: rgba(112, 108, 105, 0.2);
  }

  .barContainer {
    background-color: $gray-0;
    height: 4px;
    border-radius: 2px;

    .innerBar {
      background-color: $green;
      border-radius: 2px;
    }
  }
}

.historyButton {
  background-color: $gray-3;
  border: none;
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 48px;

  font-family: "ApercuPro", sans-serif;
  font-weight: 700;
  font-size: 13px;
  position: relative;

  .icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 16px;
    right: 16px;
  }
}
