@import "../assets/styles/variables";

.separator {
  height: 1px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  border-top-width: 1px;
  border-top-color: $gray-2;
  border-top-style: solid;
}

.dashedSeparator {
  border-top-style: dashed;
}
